<template>
  <div>
    <div v-if="showControl()" class="data-control">
      <Search />
      
      <div class="btn-set">
        <a href="#" @click="createNewPost" class="btn btn-add">
          <i class="fa fa-plus"></i> เพิ่ม<span class="mobile-hide">เนื้อหา</span>
        </a>
      </div>
    </div>
    
    <PostListComp v-if="isShowNormalList()" />
    <PostBannerListComp v-else />
  </div>
</template>

<script>
// @ is an alias to /src
import {apiRequest} from '@/utils/axios/axiosInstance.js';

import Search       from '@/components/common/Search.vue'
import PostListComp from '@/components/content/post/PostListComp.vue'
import PostBannerListComp from '@/components/content/post/PostBannerListComp.vue'

export default {
  data() {
    return {
      postType: ''
    }
  },
  components: {
    PostListComp,
    PostBannerListComp,
    Search,
  },
  created() {
    this.postType = this.$route.params.postType;

    this.$store.commit('setPageTitle', this.postTitle());
    this.$store.commit('setHeadTitle', this.postTitle());
  },
  watch: {
    $route() {
      this.postType = this.$route.params.postType;

      this.$store.commit('setPageTitle', this.postTitle());
      this.$store.commit('setHeadTitle', this.postTitle());
    }
  },
  methods: {
    /**
     * Convert slug to title
     */
    postTitle() {
      switch(this.postType) {
        default:
          return 'Posts';
        case 'recommended':
          return 'Recommended';
        case 'homeguru':
          return 'Home Guru';
        case 'testimonial':
          return 'Testimonial';
        case 'service-type':
          return 'Service Types';
        case 'service-feature':
          return 'Features';

        
        case 'post-banner':
          return 'Default Post Banner Setting';

        case 'partner-logo':
          return 'Partner Logos';
      }
    },

    /**
     * return status to show/hide control row (search and create)
     */
    showControl() {
      return this.postType!='post-banner';
    },

    /**
     * Create new post action
     */
    createNewPost() {
      this.$store.commit('setLoaderShow', true);
      
      apiRequest
        .post(
          '/content/add-post-draft', 
          { section: this.postType }
        )
        .then( (res) => {
          console.log(res.data);
          if(res.data.status==200) {
            this.$router.push('/post/'+this.postType+'/edit/' + res.data.last_insert_id);
          }else{
            alert('ระบบเกิดข้อผิดพลาด ไม่สามารถสร้างเนื้อหาใหม่ได้');
            this.$store.commit('setLoaderShow', false);
          }
        })
    },

    isShowNormalList() {
      return this.postType!='post-banner' && this.postType!='partner-logo';
    },
  }
}
</script>