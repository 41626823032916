<template>
  <div class="input">
    <div v-if="file_uploaded.row > 0" class="uploaded-item">
      <a :href="file_uploaded.result.url" target="_blank">
        <img :src="file_uploaded.result.url">
      </a>
    </div>
  </div>
</template>

<script>
import {apiRequest, apiFileUpload} from '@/utils/axios/axiosInstance.js';

export default {
  name: 'SingleFileUploader',
  props: {
    section: {
      type: String,
      default: ''
    },
    parentId: {
      type: String,
      default: ''
    },
    relateId: {
      type: String,
      default: ''
    },
    type: {
      type: String,
      default: ''
    },
    showUploader: {
      type: Boolean,
      default: true
    },


    description: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      // use when get data or upload data
      // system will disable input and button
      uploading: true,

      file: '',
      filePercentage: 0,
      file_uploaded: {
        row: 0,
        result: {}
      },
    }
  },
  created() {
    if(this.section==='' || !this.type==='' || !this.parendId==='') {
      console.log('No section or parent id data parameter !!!');
      // alert('File uploader has some problem !!!');
    }else{
      this.loadPage();
    }
  },
  methods: {
    loadPage() {
      apiRequest
        .get('/file/item' , {params: {
          section:    this.section,
          type:       this.type,
          parent_id:  this.parentId,
          relate_id:  this.relateId,
        }})
        .then( (res) => {
          this.file_uploaded = res.data;
          this.uploading = false;
        })
    },
    fileChangeHandle() {
      this.file = this.$refs.file.files[0];
      this.filePercentage = 0;

      if(this.file.size>=35000000) {
        alert('ขนาดไฟล์เกิน 5MB กรุณาลดขนาดไฟล์ก่อน upload')
        this.file = '';
        this.filePercentage = 0;
        return '';
      }else{

        this.uploading = true;

        let formData = new FormData();
        formData.append('file', this.file);
        formData.append("section",  this.section);
        formData.append("parent_id",this.parentId);
        formData.append("relate_id",this.relateId);
        formData.append("type",this.type);
        formData.append("is_replace",1);

        apiFileUpload
          .post('/file/upload', formData, {
            onUploadProgress: function(progressEvent) {
              this.filePercentage = Math.round( (progressEvent.loaded * 100) / progressEvent.total );
            }.bind(this)
          })
          .then((res) => {
            // console.log(res.data);
            this.file = '';
            this.$refs.file.value = null;
            this.file_uploaded = res.data;
            this.uploading = false;
          })
      }
    },

    /**
     * remove file item
     */
    removeFile(file) {
      let conf = confirm('คุณต้องการจะลบไฟล์ '+file.name+' ใช่หรือไม่?');
      if(conf) {
        this.$store.commit('setLoaderShow', true);
        apiRequest
          .post('/file/remove' , {
            file_id: file.fileID
          })
          .then( (res) => {
            if(res.data.status==200) this.file_uploaded.result = {};
            this.$store.commit('setLoaderShow', false);
          });
      }
    },
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.file-input {
  margin-bottom: 10px;;
}
.preload {
  display: block;
  margin: 5px 0 0 0;
  font-size: 0;
  line-height: 0;
  height: 5px;
  width: 100%;
  background: #eeeeee;
}
.preload-bar {
  display: block;
  width: 0;
  height: 100%;
  background: #39f;
}

.important {
  color: #000
}

.uploaded-item {
  position: relative;
}
.uploaded-item img {
  width: 100%;
  max-width: 300px;
}
.uploaded-item .remove-item {
  display: block;
  margin-bottom: 10px;
  color: #c00;
}
</style>
