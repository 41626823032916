<template>
  <div class="post-list">
    <div v-if="posts.total==0">
      <EmptyBlockComp />
    </div>
    <section v-else class="data-list">
      <table class="tb-data">
        <colgroup>
          <col />
          <col class="col-date" />
        </colgroup>
        <thead>
          <tr>
            <th>หัวข้อเนื้อหา</th>
            <th>แก้ไขล่าสุด</th>
          </tr>
        </thead>

        <tbody>
          <tr v-for="(item, i) in posts.result" :key="i">
            <td>
              <div class="item-detail">
                <router-link :to="getEditPath(item.ID)" class="item-title">
                  {{ item.title }}
                  <span class="item-status" v-if="item.status=='draft'">- (draft)</span>
                </router-link>
              </div>
              <ul class="item-action">
                <li><router-link :to="getEditPath(item.ID)" class="edit">แก้ไข</router-link></li>
                <li><a href="#" @click.prevent="deleteItem(item.ID)" class="delete" >ลบ</a></li>
              </ul>
            </td>
            <td>{{ item.date_modified | formatDate }}</td>
          </tr>
        </tbody>

        <tfoot>
          <tr>
            <th>หัวข้อเนื้อหา</th>
            <th>แก้ไขล่าสุด</th>
          </tr>
        </tfoot>
      </table>

    </section>
    
    <div class="data-control control-foot">
      <Pagination :slug="'/post/'+postType" :limit="qParams.limit" :total="posts.total" />
    </div>
  </div>
</template>

<script>
import {cloneObject} from '@/utils/objects.js'
import {const_data_limit} from '@/variables/const.js';
import {apiRequest} from '@/utils/axios/axiosInstance.js';

import EmptyBlockComp from '@/components/common/EmptyBlockComp.vue'
import Pagination from '@/components/common/Pagination.vue'

export default {
  data() {
    return {
      // loaded: false,
      postType: '',
      posts: {
        result: [],
        total: 0
      },
      qParams:{
        limit: const_data_limit, 
        page: 1
      }
    }
  },
  components: {
    EmptyBlockComp,
    Pagination,
  },
  created() {
    this.postType = this.$route.params.postType;
    
    // if it hasn't postType value, this page wouldn't load data
    if(this.postType) {
      this.loadPage();
    }
  },
  watch: {
    /**
     * Detect rounte path change with PostList view too,
     * this watch method will refresh data to current postType
     */
    $route(to) {
      if(this.postType != to.params.postType) {
        this.postType = to.params.postType;
        this.loadPage();
      }
    }
  },
  methods: {
    loadPage() {
      this.$store.commit('setLoaderShow', true);
      apiRequest
        .get(
          '/content/post/'+this.postType, 
          { params: cloneObject(this.qParams) }
        )
        .then( (res) => {
          if(res.data.status===200) {
            this.posts.result = res.data.result;
            this.posts.total = Number(res.data.total);
          }

          this.$store.commit('setLoaderShow', false);
        })
    },

    /**
     * Set query params and return in object variable
     */
    getQueryParams() {
      // if route query params has search, clone all params to this page params
      if(this.$route.query.q!==undefined) {
        Object.assign(this.qParams, cloneObject(this.$route.query));
      }
      // if it had only page params, assign to this page params
      else if(this.$route.query.page){
        this.qParams.page = this.$route.query.page;
      }

      return cloneObject(this.qParams);
    },

    /**
     * Get router path for edit
     */
    getEditPath(conentId) {
      return '/post/'+this.postType+'/edit/'+conentId;
    },

    /**
     * Delete post item
     *  after delete, this page will refresh
     */
    deleteItem(contentId) { //
      let conf = confirm("คุณต้องการลบเนื้อหานิใช่หรือไม่?");
      if(conf) {
        this.$store.commit('setLoaderShow', true);
        apiRequest
          .delete('/content/item', {
            data: { post_id: contentId }
          })
          .then( () => this.loadPage() );
      }
    }
  }
}
</script>

<style scoped>
.tb-data .col-date  {
  width: 15%;
  max-width: 150px;
}
</style>