<template>
  <div
    v-if="isShow"
    class="pagination" 
  >
    <form>
      <span class="result">{{ getResultMsg() }}</span>
      <router-link 
        v-if="!disablePrev"
        :to="{ path: slug, query: prevParam }"
        class="page-prev"
      >
        <i class="fa fa-chevron-left"></i> Prev
      </router-link>

      <input type="text" name="page" v-model="customPage" @keypress="goto()">
  
      <router-link 
        v-if="!disableNext"
        :to="{ path: slug, query: nextParam }"
        class="page-next"
      >
        Next <i class="fa fa-chevron-right"></i>
      </router-link>
    </form>
  </div>
</template>

<script>
import {const_data_limit} from '@/variables/const.js';
import {cloneObject} from '@/utils/objects.js'

export default {
  name: 'Pagination',
  props: {
    slug: {
      type: String, 
      default: ''
    },
    limit: {
      type: Number,
      default: 0
    },
    total: {
      type: Number,
      default: 0
    }
  },
  data() {
    return {
      isShow: false,
      currentPage: 1,
      customPage: 1,
      totalPage: 1,
      queryParams: {},
      disablePrev: true,
      disableNext: true,
      prevParam: {},
      nextParam: {},
    }
  },
  created() {    
    // if no slug, pagination will not show
    // if(this.slug==='') return;
    
    // check for passing limit data from parent
    if(this.limit===0) {
      this.limit = const_data_limit;
    }

    // if limit lower than total, pagination will not show
    if( this.total <= this.limit ) return;

    // initial value
    this.calTotalPage();
    this.detectBtnStatus();
    this.getQueryParams();
    this.setQueryParams();

    this.isShow = true;
    this.customPage = this.currentPage;
  },

  watch: {
    /**
     * Validate value when use key data
     */
    customPage:function(newValue) {
      let val = parseInt(newValue);
      
      // detect space bar or 0, change var to 1
      // if(val===' ' || val==='' || isNaN(val)) this.customPage = 1;

      // detect max number
      if(val > this.totalPage) this.customPage = this.totalPage;
    },
  },
  methods: {
    calTotalPage() {
      if( this.total <= this.limit ) return;

      this.totalPage = Math.trunc(this.total / this.limit);
      if( this.total%this.limit != 0 ) {
        this.totalPage++;
      }
    },
    detectBtnStatus() {
      if(this.$route.query.page) {
        this.currentPage = parseInt(this.$route.query.page);
      }
      if(this.currentPage>1) {
        this.disablePrev = false;
      }
      if(this.totalPage > this.currentPage) {
        this.disableNext = false;
      }
    },
    getQueryParams() {
      this.queryParams = cloneObject(this.$route.query);
      if(this.queryParams.page) {
        delete this.queryParams.page;
      }
    },
    setQueryParams() {
      this.prevParam = cloneObject(this.queryParams);
      this.nextParam = cloneObject(this.queryParams);
      
      // set previous button query params
      // if current page is 2 or lower, not assign page query params
      if(this.currentPage >= 2) {
        this.prevParam.page = this.currentPage - 1;
      }
      
      // set next button query params
      // if current page is not total page or lower, not assign page query params
      if(this.currentPage < this.totalPage) {
        this.nextParam.page = this.currentPage + 1;
      }
    },
    
    /**
     * Page status in text format
     */
    getResultMsg() {
      return 'Page '+this.currentPage+' from '+this.totalPage;
    },

    /**
     * go to custom page 
     */
    goto() {
      if(this.currentPage==this.customPage) return;

      // console.log(this.$route.path);
      // let q = cloneObject(this.$route.query);
      // q.page = this.customPage;
      // console.log(q);
      // this.$router.push({
      //   // path: this.$route.path,
      //   params: q
      // });
    }
  }
}
</script>

<style scoped>
.pagination:after {content: "";display: table;clear: both;}
.pagination {float: right;}
.pagination span,
.pagination input,
.pagination a {
	display: block;
  float: left;
  border-radius: 0;
}
.pagination span {padding: 4px 9px;}
.pagination a {
  padding: 4px 12px 3px 12px;
  border-radius: 4px;
	font-size: 14px;
  line-height: 20px;
  color: #fff;
  background: #336699;
}
.pagination a+a { margin-left: 3px;}
.pagination a[disabled] { background: #ccc; color: #6f757c !important;}
.pagination i { font-size: 12px; }
.pagination input {
  margin: 0 4px;
	padding: 4px 0;
  border-radius: 3px;
	width: 48px;
	text-align: center;
}
</style>